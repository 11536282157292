import { chain, merge } from 'lodash';
import { AssetOptions } from 'typings/custom';

const defaultOptions: AssetOptions = {
  q: 80,
  w: 500,
};

export const getAssetUrl = (
  srcUrl: string | undefined,
  options?: AssetOptions
) => {
  const qs = chain(merge({}, defaultOptions, options))
    .toPairs()
    .map((kv) => kv.join('='))
    .join('&')
    .value();
  return `${srcUrl}?${qs}`;
};
