import cx from 'classnames';
import React from 'react';

interface Props {
  children: React.ReactNode;
  loose?: boolean;
  className?: string;
}

const WideContainer = ({ children, loose, className }: Props) => {
  return (
    <div
      className={cx('md:mx-0', { '-mx-2': loose, '-mx-6': !loose }, className)}
    >
      {children}
    </div>
  );
};

export default WideContainer;
