import { Asset } from 'contentful';
import { AssetOptions } from 'typings/custom';

import { getAssetUrl } from './get-asset-url';

const GIF_WIDTH = 250;

export const getFluidImage = (
  asset: Asset | undefined,
  options?: AssetOptions,
  aspectRatio = 1.2
): any => {
  // Downsize gifs for smaller filesize
  const isGif = asset?.fields.file?.url.endsWith('gif');
  const downsizedOptions = options || {};
  if (isGif) {
    Object.assign(downsizedOptions, {
      w: GIF_WIDTH,
    });
  }

  return {
    srcSet: getAssetUrl(asset?.fields.file?.url, downsizedOptions),
    srcSetWebp: getAssetUrl(asset?.fields.file?.url, {
      ...downsizedOptions,
      fm: 'webp',
    }),
    aspectRatio,
  };
};
